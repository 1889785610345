<template>
  <h5>Сотрудники</h5>
  <DataTable
    :value="employee"
    :paginator="true"
    class="p-datatable-customers p-datatable-striped"
    :rows="10"
    dataKey="id"
    :rowHover="true"
    :autoLayout="true"
    :loading="isLoading"
  >
    <template #header>
      <form v-if="permissions['edit_client']">
        <div class="p-formgroup-inline">
          <div class="p-field">
            <label for="lastname" class="p-sr-only">Фамилия</label>
            <InputText
              id="lastname"
              placeholder="Фамилия"
              v-model.trim="lastname.val"
              :required="true"
              @blur="clearValidity('lastname')"
              :class="!lastname.isValid ? 'p-invalid' : ''"
              :disabled="!company"
            />
          </div>
          <div class="p-field">
            <label for="name" class="p-sr-only">Имя</label>
            <InputText
              id="name"
              placeholder="Имя"
              v-model.trim="name.val"
              :required="true"
              @blur="clearValidity('name')"
              :class="!name.isValid ? 'p-invalid' : ''"
              :disabled="!company"
            />
          </div>
          <div class="p-field">
            <label for="email" class="p-sr-only">E-mail</label>
            <InputText
              id="email"
              placeholder="E-mail"
              v-model.trim="email.val"
              :required="true"
              @blur="clearValidity('email')"
              :class="!email.isValid ? 'p-invalid' : ''"
              :disabled="!company"
            />
          </div>
          <div class="p-field">
            <label for="phone" class="p-sr-only">Телефон</label>
            <InputMask
              id="phone"
              mask="+7-999-999-99-99"
              placeholder="Телефон"
              v-model.trim="phone.val"
              :required="true"
              @blur="clearValidity('phone')"
              :class="!phone.isValid ? 'p-invalid' : ''"
              :disabled="
                !company ||
                (!permissions['change_employee_telephone_number'] && editMode)
              "
              :autoClear="false"
            />
          </div>
          <div class="p-field">
            <label for="post" class="p-sr-only">Должность</label>
            <InputText
              id="post"
              placeholder="Должность"
              v-model.trim="post.val"
              :required="true"
              @blur="clearValidity('post')"
              :class="!post.isValid ? 'p-invalid' : ''"
              :disabled="!company"
            />
          </div>
            <div class="checkbox-container">
              <Checkbox style="max-width: 30px" id="sms_enabled" v-model="smsAllowed" :binary="true"/>
              <label for="sms_enabled" class="ml-2" style="cursor: pointer;">Получать сообщения</label>
          </div>
          <Button
            v-if="!editMode"
            type="button"
            icon="pi pi-plus"
            @click="checkUser"
            :disabled="!company"
          ></Button>
          <Button
            v-if="editMode"
            type="button"
            style="width: 102px"
            @click="submitEditing"
            :disabled="!company"
            >Сохранить</Button
          >
        </div>
      </form>
    </template>
    <template #empty>Список сотрудников пуст</template>
    <template #loading>Загрузка...</template>
    <Column field="lastname" header="Фамилия" :sortable="true">
      <template #body="slotProps">{{ slotProps.data.lastname }}</template>
    </Column>
    <Column field="firstname" header="Имя" :sortable="true">
      <template #body="slotProps">{{ slotProps.data.firstname }}</template>
    </Column>
    <Column field="email" header="e-mail" :sortable="true">
      <template #body="slotProps"
        ><a :href="'mailto:' + slotProps.data.email">{{
          slotProps.data.email
        }}</a></template
      >
    </Column>
    <Column field="phone" header="Телефон" :sortable="true">
      <template #body="slotProps"
        ><a :href="'tel:' + slotProps.data.phone">{{
          slotProps.data.phone
        }}</a></template
      >
    </Column>
    <Column field="post" header="Должность" :sortable="true">
      <template #body="slotProps">{{
        slotProps.data.post ? slotProps.data.post : "-"
      }}</template>
    </Column>
    <Column field="smsAllowed" header="Получать сообщения">
      <template #body="slotProps">
        <Checkbox v-model="slotProps.data.smsAllowed" :binary="true" @change="toggleSmsAllowed(slotProps.data)"/>
      </template>
    </Column>
    <Column>
      <template #body="slotProps">
        <Button
          v-if="permissions['edit_client']"
          type="button"
          icon="pi pi-pencil"
          class="p-button-warning"
          @click="loadEmployeeForEdit(slotProps.data.id)"
        ></Button>
      </template>
    </Column>
    <Column>
      <template #body="slotProps">
        <Button
          v-if="!slotProps.data.block && permissions['edit_client']"
          icon="pi pi-lock"
          class="p-button-rounded p-button-success p-mr-2"
          v-tooltip="'Заблокировать пользователя'"
          @click="blockUser(slotProps.data, 'block')"
        />
        <Button
          v-if="slotProps.data.block && permissions['edit_client']"
          icon="pi pi-unlock"
          class="p-button-rounded p-button-warning"
          v-tooltip="'Разблокировать пользователя'"
          @click="blockUser(slotProps.data, 'unblock')"
        />
      </template>
    </Column>
    <Column>
      <template #body="slotProps">
        <Button
          v-if="permissions['delete_employees']"
          icon="pi pi-trash"
          class="p-button-danger"
          v-tooltip="'Удалить сотрудника'"
          @click="confirmDeleting(slotProps.data.id)"
          :disabled="isLoading"
        />
      </template>
    </Column>
  </DataTable>
  <Dialog
    header="Внимание!"
    v-model:visible="displayConfirmation"
    :style="{ width: '350px' }"
    :modal="true"
  >
    <div>
      <div class="confirmation-content">
        <div align="center">
          <i
            class="pi pi-exclamation-triangle p-mr-3"
            style="font-size: 2rem"
          />
        </div>
        <div align="center" v-if="existedUser.role === 'EMPLOYEE_OF_CLIENT'">
          Такой сотрудник уже существует. Вы уверены, что хотите привязать его к
          своей компании?
        </div>
        <div align="center" v-else>
          Такой пользователь уже существует, но его нельзя привязать, так как он
          не является сотрудником клиента
        </div>
        <div class="p-mt-4" align="center">
          {{ existedUser?.firstname }} {{ existedUser?.lastname }}
          {{ existedUser?.phone }}
        </div>
      </div>
    </div>
    <template #footer>
      <div v-if="existedUser.role === 'EMPLOYEE_OF_CLIENT'">
        <Button
          label="Нет"
          icon="pi pi-times"
          @click="closeConfirmation"
          class="p-button-text"
        />
        <Button
          label="Да"
          icon="pi pi-check"
          @click="closeConfirmationAndAdd"
          class="p-button-text"
        />
      </div>
      <div v-else>
        <Button
          label="Понятно"
          icon="pi pi-check"
          @click="closeConfirmation"
          class="p-button-text"
        />
      </div>
    </template>
  </Dialog>
  <ConfirmDialog group="deleteUserDialog"></ConfirmDialog>
</template>

<script>
import { environment } from "@/config";

export default {
  props: ["company"],
  data() {
    return {
      id: null,
      isLoading: false,
      employee: null,
      formIsValid: true,
      editMode: false,
      displayConfirmation: false,
      existedUser: null,
      post: {
        val: "",
        isValid: true,
      },
      name: {
        val: "",
        isValid: true,
      },
      lastname: {
        val: "",
        isValid: true,
      },
      email: {
        val: "",
        isValid: true,
      },
      phone: {
        val: "",
        isValid: true,
      },
      smsAllowed: true,
    };
  },
  created() {
    this.loadEmployees();
  },
  computed: {
    permissions() {
      return this.$store.getters.getPermissions;
    },
    role() {
      return this.$store.getters.getRole;
    },
  },
  methods: {
    async toggleSmsAllowed(data) {
      await this.updatePermissions(data.id, data.smsAllowed);
      this.$toast.add({
        severity: "success",
        summary: "Сотрудник обновлён",
        detail: `Сообщения ${data.smsAllowed ? 'включены' : 'отключены'}`,
        life: 6000,
      });
    },

    confirmDeleting(id) {
      this.$confirm.require({
        message: "Вы действительно хотите удалить сотрудника?",
        header: "Удалить сотрудника?",
        group: "deleteUserDialog",
        icon: "pi pi-exclamation-triangle",
        acceptLabel: "Да",
        rejectLabel: "Нет",
        accept: () => {
          this.deleteUser(id);
        },
      });
    },
    closeConfirmation() {
      this.displayConfirmation = false;
    },
    closeConfirmationAndAdd() {
      this.displayConfirmation = false;
      this.submitWithChecking();
    },
    clearValidity(input) {
      this[input].isValid = true;
    },
    validateForm() {
      this.formIsValid = true;

      this.name.isValid = true;
      this.email.isValid = true;
      this.phone.isValid = true;

      if (!this.name.val) {
        this.name.isValid = false;
        this.formIsValid = false;
      }
      if (!this.phone.val) {
        this.phone.isValid = false;
        this.formIsValid = false;
      }
    },
    async checkUser() {
      this.validateForm();
      this.existedUser = null;

      if (!this.formIsValid) {
        return;
      }
      this.isLoading = true;

      try {
        const response = await fetch(
          `${environment.apiUrl}/get_user_by_phone`,
          {
            headers: {
              Authorization: `Bearer ${this.$store.getters.token}`,
              "Content-Type": "application/json",
            },
            method: "POST",
            body: JSON.stringify({ phone: this.phone.val }),
          }
        );

        if (response.status === 404) {
          await this.submitWithChecking();
        } else {
          this.existedUser = await response.json();
          this.displayConfirmation = true;
        }
      } catch (error) {
        console.log(error);
      }
      this.isLoading = false;
    },

    async updatePermissions(userId, smsAllowed) {
      let permissionUrl = `${environment.apiUrl}/permissions/${userId}`;

      await fetch(permissionUrl, {
        method: "PUT",
        headers: {
          Authorization: `Bearer ${this.$store.getters.token}`,
          "Content-Type": "application/json"
        },
        body: JSON.stringify({
          userId: userId,
          smsAllowed: smsAllowed
        })
      })
    },

    async submitWithChecking() {
      this.isLoading = true;

      if (this.email.val === "") {
        this.email.val = null;
      }

      let method = "POST";
      let url = `${environment.apiUrl}/users`;
      let payloadCompanies = [this.company];
      let actionPayload;
      if (this.existedUser?.companies) {
        this.existedUser.companies = payloadCompanies.concat(
          this.existedUser.companies
        );
        method = "PUT";
        url += `/${this.existedUser.id}`;
        actionPayload = {
          ...this.existedUser,
        };
      } else {
        actionPayload = {
          firstname: this.name.val,
          lastname: this.lastname.val,
          email: this.email.val,
          phone: this.phone.val,
          post: this.post.val,
          role: "EMPLOYEE_OF_CLIENT",
          regions: [],
          companies: payloadCompanies,
        };
      }

      try {
        const response = await fetch(url, {
          method: method,
          headers: {
            Authorization: `Bearer ${this.$store.getters.token}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify(actionPayload),
        });

        const responseData = await response.json();

        if (method === "POST" && response.status !== 201) {
          throw new Error(responseData.message || "Ошибка создания клиента!");
        }
        if (method === "PUT" && response.status !== 200) {
          throw new Error(responseData.message || "Ошибка обновления клиента!");
        }

        if (!this.smsAllowed) {
          await this.updatePermissions(responseData.id, this.smsAllowed)
        }

        await this.loadEmployees();

        this.name.val = "";
        this.lastname.val = "";
        this.email.val = "";
        this.phone.val = "";
        this.id = null;
        this.post.val = "";
        this.editMode = false;
        this.smsAllowed = true;

        this.$toast.add({
          severity: "success",
          summary: "",
          detail: `Клиент добавлен!`,
          life: 6000,
        });
      } catch (err) {
        const error = err.message || "Не получилось создать клиента";
        this.$toast.add({
          severity: "error",
          summary: "Ошибка при создании клиента!",
          detail: error,
          life: 6000,
        });
      }
      this.isLoading = false;
    },
    async submitEditing() {
      this.isLoading = true;
      this.validateForm();

      if (!this.formIsValid) {
        return;
      }
      if (this.email.val === "") {
        this.email.val = null;
      }

      this.isLoading = true;
      let method = "PUT";
      let url = `${environment.apiUrl}/users/${this.id}`;
      const e = this.employee.filter((e) => e.id === this.id)[0];

      const actionPayload = {
        firstname: this.name.val,
        lastname: this.lastname.val,
        email: this.email.val,
        phone: this.phone.val,
        role: "EMPLOYEE_OF_CLIENT",
        post: this.post.val,
        regions: [],
        companies: e.companies,
      };

      try {
        const response = await fetch(url, {
          method: method,
          headers: {
            Authorization: `Bearer ${this.$store.getters.token}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify(actionPayload),
        });

        const responseData = await response.json();

        if (method === "POST" && response.status !== 201) {
          throw new Error(responseData.message || "Ошибка создания клиента!");
        }
        if (method === "PUT" && response.status !== 200) {
          throw new Error(responseData.message || "Ошибка обновления клиента!");
        }

        const permissionUrl = `${environment.apiUrl}/permissions/${this.id}`;

        await fetch(permissionUrl, {
          method: method,
          headers: {
            Authorization: `Bearer ${this.$store.getters.token}`,
            "Content-Type": "application/json"
          }, body: JSON.stringify({
            userId: this.id,
            smsAllowed: this.smsAllowed
          })
        });

        await this.loadEmployees();
        this.name.val = "";
        this.lastname.val = "";
        this.email.val = "";
        this.phone.val = "";
        this.id = null;
        this.post.val = "";
        this.editMode = false;
        this.smsAllowed = true;

        this.$toast.add({
          severity: "success",
          summary: "",
          detail: `Клиент обновлён!`,
          life: 6000,
        });
      } catch (err) {
        const error = err.message || "Не удалось обновить клиента";
        this.$toast.add({
          severity: "error",
          summary: "Ошибка при обновлении клиента!",
          detail: error,
          life: 6000,
        });
      }

      this.isLoading = false;
    },
    async loadEmployees() {
      if (!this.company) return;
      this.isLoading = true;
      try {
        const response = await fetch(
            `${environment.apiUrl}/users?companyUid=${this.company.uid}`,
            {
              headers: {
                Authorization: `Bearer ${this.$store.getters.token}`,
                "Content-Type": "application/json",
              },
            }
        );
        this.employee = await response.json();
        this.employee = this.employee.filter((e) => {
          return e.role === "EMPLOYEE_OF_CLIENT";
        });

        const permissionsResponse = await fetch(
            `${environment.apiUrl}/permissions/company/${this.company.uid}`,
            {
              headers: {
                Authorization: `Bearer ${this.$store.getters.token}`,
                "Content-Type": "application/json",
              },
            }
        );

        if (!(response.ok && permissionsResponse.ok)) {
          throw new Error(`Не удалось загрузить пользователей`);
        }

        const permissions = await permissionsResponse.json();

        this.employee = this.employee.map(employee => {
          const userInfo = permissions.find(permission => permission.userId === employee.id);
          return { ...employee, smsAllowed: userInfo ? userInfo.smsAllowed : true }
        });
      } catch (err) {
        const error = err.message || "Не удалось загрузить список сотрудников";
        this.$toast.add({
          severity: "error",
          summary: "Ошибка при загрузке списка сотрудников!",
          detail: error,
          life: 6000,
        });
      }

      this.isLoading = false;
    },
    async deleteUser(id) {
      this.isLoading = true;

      try {
        await fetch(`${environment.apiUrl}/permissions/${id}`, {
          method: "DELETE",
          headers: {
            Authorization: `Bearer ${this.$store.getters.token}`,
            "Content-Type": "application/json",
          },
        });

        await fetch(`${environment.apiUrl}/users/${id}`, {
          method: "DELETE",
          headers: {
            Authorization: `Bearer ${this.$store.getters.token}`,
            "Content-Type": "application/json",
          },
        }).then(response => {
          if (response.status === 400) {
            throw new Error("Пользователя нельзя удалить");
          } else {
            if (response.status !== 200) {
              throw new Error("Что-то пошло не так. Обратитесь к разработчику");
            }
          }
        });

        await this.loadEmployees();

        this.$toast.add({
          severity: "success",
          summary: "",
          detail: `Пользователь удален!`,
          life: 6000,
        });
      } catch (err) {
        const error = err.message || "Не получилось удалить пользователя";
        this.$toast.add({
          severity: "error",
          summary: "Ошибка при удалении пользователя!",
          detail: error,
          life: 6000,
        });
      }

      this.isLoading = false;
    },
    async blockUser(data, state) {
      this.isLoading = true;

      try {
        const response = await fetch(
          `${environment.apiUrl}/users/${data.id}/${state}`,
          {
            method: "POST",
            headers: {
              Authorization: `Bearer ${this.$store.getters.token}`,
              "Content-Type": "application/json",
            },
          }
        );

        if (response.status !== 200) {
          const responseData = await response.json();
          throw new Error(
            responseData.message || "Ошибка блокирования пользователя!"
          );
        }

        await this.loadEmployees();

        this.$toast.add({
          severity: "success",
          summary: "",
          detail: `Пользователь ${
            state === "block" ? "заблокирован" : "разблокирован"
          }!`,
          life: 6000,
        });
      } catch (err) {
        const error = err.message || "Не получилось заблокировать пользователя";
        this.$toast.add({
          severity: "error",
          summary: "Ошибка при блокировании пользователя!",
          detail: error,
          life: 6000,
        });
      }

      this.isLoading = false;
    },
    async loadEmployeeForEdit(id) {
      this.editMode = true;
      const e = this.employee.filter((e) => e.id === id)[0];
      this.name.val = e.firstname;
      this.lastname.val = e.lastname;
      this.email.val = e.email;
      this.phone.val = e.phone;
      this.id = e.id;
      this.post.val = e.post;
      this.smsAllowed = e.smsAllowed;
    },
  },
};
</script>

<style scoped>
.p-formgroup-inline div {
  width: 40%;
}
.checkbox-container {
  display: flex;
  flex-wrap: wrap;
  margin: 5px;
}

</style>
