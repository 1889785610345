<template>
  <h5>Техника</h5>
  <DataTable
    :value="techincs"
    :paginator="true"
    class="p-datatable-customers p-datatable-striped datatable"
    :rows="10"
    dataKey="id"
    :rowHover="true"
    :autoLayout="true"
    :loading="isLoading"
    v-model:filters="filters"
    filterDisplay="row"
    responsiveLayout="scroll"
    @row-click="setFilter"
  >
    <template #header>
      <form @submit.prevent="submitForm" v-if="permissions['edit_client']">
        <div class="p-formgroup-inline">
          <div class="p-field">
            <label for="ttype" class="p-sr-only">Тип техники</label>
            <Dropdown
              id="ttype"
              v-model="ttype.val"
              :options="technicListButItIsNotTechnicList"
              :required="true"
              optionLabel="name"
              :filter="true"
              placeholder="Тип техники"
              :disabled="!company || isLoading"
              @blur="clearValidity('ttype')"
              :class="!ttype.isValid ? 'p-invalid' : ''"
              @change="technicTypeChanged"
            >
            </Dropdown>
          </div>
          <div class="p-field">
            <label for="producer" class="p-sr-only">Производитель</label>
            <Dropdown
              id="producer"
              v-model="producer.val"
              :options="technicProducers"
              :required="true"
              optionLabel="name"
              placeholder="Производитель"
              :disabled="!company || isLoading || !ttype.val"
              @blur="clearValidity('producer')"
              :class="!producer.isValid ? 'p-invalid' : ''"
              @change="producerChanged"
            />
          </div>
          <div class="p-field">
            <label for="model" class="p-sr-only">Модель</label>
            <Dropdown
              id="model"
              v-model="model.val"
              :options="technicModels"
              :required="true"
              :filter="true"
              optionLabel="name"
              placeholder="Модель"
              :disabled="!company || isLoading || technicModels.length === 0"
              @blur="clearValidity('model')"
              :class="!model.isValid ? 'p-invalid' : ''"
            />
          </div>
          <div class="p-field">
            <label for="series" class="p-sr-only">Серия</label>
            <InputText
              id="series"
              placeholder="Серия"
              v-model.trim="series.val"
              :required="true"
              @blur="clearValidity('series')"
              :class="!series.isValid ? 'p-invalid' : ''"
              :disabled="!company || isLoading"
            />
          </div>
          <div class="p-field">
            <label for="serviceEndTime" class="p-sr-only"
              >Дата окончания гарантии</label
            >
            <InputMask
              v-model="serviceEndTime.val"
              mask="99.99.9999"
              :auto-clear="false"
              placeholder="Дата гарантии"
              :class="{ 'p-invalid': !serviceEndTime.isValid }"
            />
          </div>
          <div class="p-field">
            <label for="placeOfStay" class="p-sr-only">Серия</label>
            <InputText
              id="placeOfStay"
              placeholder="Примечание"
              v-model.trim="placeOfStay.val"
              :required="true"
              @blur="clearValidity('placeOfStay')"
              :class="!placeOfStay.isValid ? 'p-invalid' : ''"
              :disabled="!company || isLoading"
            />
          </div>
          <Button
            v-if="!editMode"
            type="button"
            icon="pi pi-plus"
            @click="submitForm"
            :disabled="!company"
          ></Button>
          <Button
            v-if="editMode"
            type="button"
            style="width: 102px"
            @click="submitForm"
            :disabled="!company"
            >Сохранить
          </Button>
        </div>
      </form>
    </template>
    <template #empty>Список техники пуст</template>
    <template #loading>Загрузка...</template>
    <Column
      field="technicTypeInfoName"
      header="Тип"
      :sortable="true"
      :showFilterMenu="false"
    >
      <template #body="slotProps"
        >{{ slotProps.data.technicTypeInfoName }}
      </template>
      <template #filter="{ filterModel, filterCallback }">
        <InputText
          type="text"
          v-model="filterModel.value"
          @input="filterCallback()"
          class="p-column-filter filter-1"
          :placeholder="``"
          v-tooltip.top.focus="''"
        />
      </template>
    </Column>
    <Column
      field="technicProducerInfoName"
      header="Производитель"
      :sortable="true"
      :showFilterMenu="false"
    >
      <template #body="slotProps"
        >{{ slotProps.data.technicProducerInfoName }}
      </template>
      <template #filter="{ filterModel, filterCallback }">
        <InputText
          type="text"
          v-model="filterModel.value"
          @input="filterCallback()"
          class="p-column-filter filter-1"
          :placeholder="``"
          v-tooltip.top.focus="''"
        />
      </template>
    </Column>
    <Column
      field="technicModelInfoName"
      header="Модель"
      :sortable="true"
      :showFilterMenu="false"
    >
      <template #body="slotProps"
        >{{ slotProps.data.technicModelInfoName }}
      </template>
      <template #filter="{ filterModel, filterCallback }">
        <InputText
          type="text"
          v-model="filterModel.value"
          @input="filterCallback()"
          class="p-column-filter filter-1"
          :placeholder="``"
          v-tooltip.top.focus="''"
        />
      </template>
    </Column>
    <Column
      field="series"
      header="Серия"
      :sortable="true"
      :showFilterMenu="false"
    >
      <template #body="slotProps">{{ slotProps.data.series }}</template>
      <template #filter="{ filterModel, filterCallback }">
        <InputText
          type="text"
          v-model="filterModel.value"
          @input="filterCallback()"
          class="p-column-filter filter-1"
          :placeholder="``"
          v-tooltip.top.focus="''"
        />
      </template>
    </Column>
    <Column
      field="serviceEndTime"
      header="Дата окончания гарантии"
      :sortable="true"
      :showFilterMenu="false"
    >
      <template #body="slotProps">
        <div
          class="p-text-center"
          :class="
            isWarrantyEnded(slotProps.data.serviceEndTimeIso)
              ? ''
              : 'green-background'
          "
        >
          {{ slotProps.data.serviceEndTime }}
        </div>
      </template>
      <template #filter="{ filterModel, filterCallback }">
        <InputText
          type="text"
          v-model="filterModel.value"
          @input="filterCallback()"
          class="p-column-filter filter-1"
          :placeholder="``"
          v-tooltip.top.focus="''"
        />
      </template>
    </Column>
    <Column
      field="placeOfStay"
      header="Примечание"
      :sortable="true"
      :showFilterMenu="false"
    >
      <template #body="slotProps">
        {{ slotProps.data.placeOfStay ? slotProps.data.placeOfStay : "-" }}
      </template>
    </Column>
    <Column>
      <template #body="slotProps">
        <Button
          v-if="permissions['edit_client']"
          type="button"
          icon="pi pi-pencil"
          class="p-button-warning"
          @click="loadTechnicForEdit(slotProps.data.id)"
        ></Button>
      </template>
    </Column>
    <Column>
      <template #body="slotProps">
        <Button
          v-if="permissions['edit_client']"
          type="button"
          icon="pi pi-minus"
          class="p-button-danger"
          @click="deleteTechincConfirm(slotProps.data.id)"
        ></Button>
      </template>
    </Column>
  </DataTable>
  <Dialog
    header="Внимание!"
    v-model:visible="displayConfirmation"
    :style="{ width: '350px' }"
    :modal="true"
  >
    <div class="confirmation-content">
      <i class="pi pi-exclamation-triangle p-mr-3" style="font-size: 2rem" />
      <span>{{ deleteTechnicAlert() }}</span>
    </div>
    <template #footer>
      <Button
        label="Нет"
        icon="pi pi-times"
        @click="closeConfirmation"
        class="p-button-text"
      />
      <Button
        label="Да"
        icon="pi pi-check"
        @click="closeConfirmationAndSelect"
        class="p-button-text"
      />
    </template>
  </Dialog>
  <Dialog
    header="Создание нового типа техники"
    v-model:visible="displayCreateNewTechnicDialog"
    :style="{ width: '550px' }"
    :modal="true"
  >
    <div class="flex column">
      <div class="p-mt-2">
        <InputText
          id="newType"
          placeholder="Тип техники"
          v-model.trim="newTechnicType.val"
          :required="true"
          style="width: 100%"
          :class="!newTechnicType.isValid ? 'p-invalid' : ''"
          :disabled="isLoading"
        />
      </div>
      <div class="p-mt-2">
        <InputText
          id="newProducer"
          placeholder="Производитель"
          v-model.trim="newTechnicProducer.val"
          :required="true"
          style="width: 100%"
          :class="!newTechnicProducer.isValid ? 'p-invalid' : ''"
          :disabled="isLoading"
        />
      </div>
    </div>
    <template #footer>
      <Button
        label="Отменить"
        icon="pi pi-times"
        @click="closeCreateNewTechnicDialog"
        class="p-button-text"
      />
      <Button
        label="Создать"
        icon="pi pi-check"
        @click="closeCreateNewTechnicDialogAndSelect"
        class="p-button-text"
      />
    </template>
  </Dialog>
</template>

<script>
import { environment } from "@/config";
import { FilterMatchMode } from "primevue/api";
import moment from "moment";

export default {
  props: ["company"],
  data() {
    return {
      id: null,
      isLoading: false,
      techincs: null,
      filters: {
        id: { value: null, matchMode: FilterMatchMode.CONTAINS },
        series: { value: null, matchMode: FilterMatchMode.CONTAINS },
        technicTypeInfoName: {
          value: null,
          matchMode: FilterMatchMode.CONTAINS,
        },
        technicProducerInfoName: {
          value: null,
          matchMode: FilterMatchMode.CONTAINS,
        },
        technicModelInfoName: {
          value: null,
          matchMode: FilterMatchMode.CONTAINS,
        },
        serviceEndTime: { value: null, matchMode: FilterMatchMode.CONTAINS },
      },
      displayCreateNewTechnicDialog: false,
      technicModels: [],
      technicProducers: [],
      loadedTechnicModels: [],
      formIsValid: true,
      displayConfirmation: false,
      whatToDeleteId: null,
      editMode: false,
      newTechnicType: {
        val: "",
        isValid: true,
      },
      newTechnicProducer: {
        val: "",
        isValid: true,
      },
      number: {
        val: "",
        isValid: true,
      },
      series: {
        val: "",
        isValid: true,
      },
      year: {
        val: null,
        isValid: true,
      },
      ttype: {
        val: "",
        isValid: true,
      },
      model: {
        val: "",
        isValid: true,
      },
      producer: {
        val: "",
        isValid: true,
      },
      serviceEndTime: {
        val: "",
        isValid: true,
      },
      placeOfStay: {
        val: "",
        isValid: true,
      },
      technicListButItIsNotTechnicList: null,
    };
  },
  created() {
    this.loadTechnic();
    this.getTechnicModels();
    this.technicListButItIsNotTechnicList = this.technicList;
  },
  computed: {
    permissions() {
      return this.$store.getters.getPermissions;
    },
    technicList() {
      return this.$store.getters["data/technicTypes"].filter((tt) => {
        return tt.block === false;
      });
    },
  },
  methods: {
    setFilter(data) {
      this.$router.push("/tasks?technic=" + data.data.id);
    },
    async createNewTechnic() {
      this.isLoading = true;
      try {
        const payload = {
          technicProducer: {
            name: this.newTechnicProducer.val,
            uid: "",
          },
          technicType: {
            name: this.newTechnicType.val,
            uid: "",
          },
        };
        const response = await fetch(`${environment.apiUrl}/technic_models`, {
          headers: {
            Authorization: `Bearer ${this.$store.getters.token}`,
            "Content-Type": "application/json",
          },
          method: "POST",
          body: JSON.stringify(payload),
        });
      } catch (err) {
        console.log(err);
      }
      this.isLoading = false;
    },
    async getTechnicModels() {
      this.isLoading = true;
      const response = await fetch(`${environment.apiUrl}/technic_models`, {
        headers: {
          Authorization: `Bearer ${this.$store.getters.token}`,
          "Content-Type": "application/json",
        },
      });

      this.loadedTechnicModels = await response.json();

      this.isLoading = false;
    },
    isWarrantyEnded(newDate) {
      if (newDate === undefined) return true;
      let date = new Date(newDate);
      let currentDate = Date.now();
      return currentDate > date;
    },
    //Можно убрать
    getDateFromIso(newDate) {
      if (newDate === undefined) return "-";
      let date = new Date(newDate);
      const year = date.getFullYear();
      let month = date.getMonth() + 1;
      let dt = date.getDate();

      if (dt < 10) {
        dt = "0" + dt;
      }
      if (month < 10) {
        month = "0" + month;
      }
      return `${dt}.${month}.${year}`;
    },
    clearValidity(input) {
      this[input].isValid = true;
    },
    deleteTechnicAlert() {
      const t = this.techincs.filter((t) => t.id === this.whatToDeleteId)[0];
      return `Вы уверены, что удалить технику ${t.technicTypeInfo.name}, ${t.technicProducerInfo.name}, ${t.technicModelInfo.name}, ${t.series}?`;
    },
    async loadTechnicForEdit(id) {
      this.editMode = true;
      this.technicListButItIsNotTechnicList = [...this.technicList];
      const t = this.techincs.filter((t) => t.id === id)[0];
      this.ttype.val = t.technicTypeInfo;
      if (t.technicTypeInfo.block === true) {
        this.technicListButItIsNotTechnicList.push(t.technicTypeInfo);
      }
      await this.technicTypeChanged();
      this.producer.val = t.technicProducerInfo;
      await this.producerChanged();
      this.model.val = t.technicModelInfo;
      this.series.val = t.series;
      this.year.val = t?.year;
      if (t?.serviceEndTimeIso) {
        this.serviceEndTime.val = moment(t.serviceEndTimeIso).format(
          "DD.MM.YYYY"
        );
      } else {
        this.serviceEndTime.val = null;
      }
      this.placeOfStay.val = t?.placeOfStay;

      this.id = t.id;
    },
    closeConfirmation() {
      this.displayConfirmation = false;
    },
    closeConfirmationAndSelect() {
      this.closeConfirmation();
      this.deleteTechinc(this.whatToDeleteId);
    },
    openCreateNewTechnicDialog() {
      this.newTechnicType.val = this.ttype.val;
      this.newTechnicProducer.val = this.producer.val;
      this.displayCreateNewTechnicDialog = true;
    },
    closeCreateNewTechnicDialog() {
      this.displayCreateNewTechnicDialog = false;
    },
    closeCreateNewTechnicDialogAndSelect() {
      // this.displayCreateNewTechnicDialog = false
      this.createNewTechnic();
    },
    validateForm() {
      this.formIsValid = true;

      this.series.isValid = true;
      this.ttype.isValid = true;
      this.model.isValid = true;
      this.producer.isValid = true;
      this.serviceEndTime.isValid = true;

      if (!this.series.val) {
        this.series.isValid = false;
        this.formIsValid = false;
      }
      if (!this.ttype.val) {
        this.ttype.isValid = false;
        this.formIsValid = false;
      }
      if (!this.model.val) {
        this.model.isValid = false;
        this.formIsValid = false;
      }
      if (
        this.serviceEndTime.val !== "" &&
        !moment(this.serviceEndTime.val, "DD.MM.YYYY", true).isValid()
      ) {
        this.serviceEndTime.isValid = false;
        this.formIsValid = false;
      }
      if (!this.producer.val) {
        this.producer.isValid = false;
        this.formIsValid = false;
      }
    },
    async submitForm() {
      this.validateForm();

      if (!this.formIsValid) {
        return;
      }

      this.isLoading = true;
      let method = "POST";
      let url = `${environment.apiUrl}/technics`;

      const actionPayload = {
        series: this.series.val,
        year: this.year.val ?? null,
        company: this.company,
        technicTypeInfo: this.ttype.val,
        technicModelInfo: this.model.val,
        technicProducerInfo: this.producer.val,
        serviceEndTime: moment(
          this.serviceEndTime.val,
          "DD.MM.YYYY"
        ).toISOString(),
        placeOfStay: this.placeOfStay.val,
      };

      if (this.id) {
        method = "PUT";
        url += `/${this.id}`;
      }

      try {
        const response = await fetch(url, {
          method: method,
          headers: {
            Authorization: `Bearer ${this.$store.getters.token}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify(actionPayload),
        });

        const responseData = await response.json();

        if (method === "POST" && response.status !== 201) {
          throw new Error(responseData.message || "Ошибка создания техники!");
        }

        if (method === "PUT" && response.status !== 200) {
          throw new Error(responseData.message || "Ошибка обновление техники!");
        }

        await this.loadTechnic();
        this.year.val = null;
        this.series.val = "";
        this.ttype.val = {};
        this.model.val = {};
        this.producer.val = {};
        this.id = null;
        this.editMode = false;
        this.serviceEndTime.val = "";

        this.$toast.add({
          severity: "success",
          summary: "",
          detail:
            method === "POST" ? `Техника добавлена!` : `Техника обновлена!`,
          life: 6000,
        });
        this.technicListButItIsNotTechnicList = [...this.technicList];
      } catch (err) {
        const error = err.message || "Не получилось создать технику";
        this.$toast.add({
          severity: "error",
          summary: "Ошибка при создании техники!",
          detail: error,
          life: 6000,
        });
      }

      this.isLoading = false;
    },
    async loadTechnic() {
      if (!this.company) return;
      this.isLoading = true;
      try {
        const response = await fetch(
          `${environment.apiUrl}/technics?companyUid=${this.company.uid}&size=1&page=0`,
          {
            headers: {
              Authorization: `Bearer ${this.$store.getters.token}`,
              "Content-Type": "application/json",
            },
          }
        );
        this.techincs = await response.json();
        this.techincs = this.techincs.map((t) => {
          return {
            ...t,
            technicTypeInfoName: t.technicTypeInfo.name,
            technicProducerInfoName: t.technicProducerInfo.name,
            technicModelInfoName: t.technicModelInfo.name,
            serviceEndTime: this.getDateFromIso(t.serviceEndTime),
            serviceEndTimeIso: t.serviceEndTime,
          };
        });
      } catch (err) {
        const error = err.message || "Не получилось загрузить список техники";
        this.$toast.add({
          severity: "error",
          summary: "Ошибка при загрузке списка техники!",
          detail: error,
          life: 6000,
        });
      }

      this.isLoading = false;
    },
    deleteTechincConfirm(id) {
      this.displayConfirmation = true;
      this.whatToDeleteId = id;
    },
    async deleteTechinc(id) {
      try {
        const response = await fetch(`${environment.apiUrl}/technics/${id}`, {
          method: "DELETE",
          headers: {
            Authorization: `Bearer ${this.$store.getters.token}`,
            "Content-Type": "application/json",
          },
        });

        if (response.status !== 200) {
          throw new Error("Ошибка удаления техники!");
        }

        this.whatToDeleteId = null;
        await this.loadTechnic();
      } catch (err) {
        const error = err.message || "Не получилось удалить технику";
        this.$toast.add({
          severity: "error",
          summary: "Ошибка при удалении техники!",
          detail: error,
          life: 6000,
        });
      }
    },
    setHas(set, object) {
      for (const obj of set) {
        if (obj.uid === object.uid) {
          return true;
        }
      }
      return false;
    },
    async technicTypeChanged() {
      this.isLoading = true;
      let mySet = new Set();
      this.loadedTechnicModels
        .filter((l) => {
          return this.ttype.val.uid === l.technicType.uid;
        })
        .map((l) => {
          if (!this.setHas(mySet, l.technicProducer)) {
            mySet.add(l.technicProducer);
          }
        });
      this.technicProducers = [...mySet].filter((p) => {
        return p.block === false;
      });
      this.isLoading = false;
    },
    async producerChanged() {
      if (!(this.ttype.val.uid && this.producer.val.uid)) return;

      this.isLoading = true;
      // загрузим обновленного производителя
      const response = await fetch(
        `${environment.apiUrl}/technic_models?technicTypeUid=${this.ttype.val.uid}&technicProducerUid=${this.producer.val.uid}`,
        {
          headers: {
            Authorization: `Bearer ${this.$store.getters.token}`,
            "Content-Type": "application/json",
          },
        }
      );

      this.technicModels = await response.json();
      this.technicModels = this.technicModels.filter((m) => {
        return m.block === false;
      });

      this.isLoading = false;
    },
  },
};
</script>

<style scoped>
.p-formgroup-inline > div {
  width: 40%;
}

.confirmation-content {
  display: flex;
  align-items: center;
  justify-content: center;
}

.datatable {
  cursor: pointer;
}

.green-background {
  background-color: #93ffc2;
}
</style>
